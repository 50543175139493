<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">我的信息</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="display: flex;justify-content: space-between">
      <div class="div" style="align-items: start">
        <el-input class="family1 text" size="small"
                  v-model.trim="user.name" readonly>
          <template slot="prepend">网店名称</template>
        </el-input>

        <el-input class="family1 text" size="small" v-model.trim="user.vipdate" readonly>
          <template slot="prepend">会员到期</template>
        </el-input>

        <el-input class="family1 text" size="small" v-model.trim="user.regdate" readonly>
          <template slot="prepend">注册日期</template>
        </el-input>

        <el-input class="family text" size="small" v-model.trim="user.contact" maxlength="20">
          <template slot="prepend">联系人</template>
        </el-input>

        <el-input class="family text" size="small" v-model.trim="user.tel" maxlength="15">
          <template slot="prepend">联系电话</template>
        </el-input>

        <el-input class="family text" size="small" v-model.trim="user.qq"
                  oninput="value=value.replace(/\D/g,'')" maxlength="15">
          <template slot="prepend">qq号</template>
        </el-input>

        <el-input class="family text" size="small" v-model.trim="user.address" maxlength="50">
          <template slot="prepend">地址</template>
        </el-input>

        <el-button type="success" @click="updBuyer" size="small" :loading="loadingUpd"
                   icon="el-icon-edit">修改信息
        </el-button>
      </div>
      <div class="div" style="align-items: start">
        <el-card shadow="never" style="width: 301px;margin-bottom: 25px">
          <div slot="header">
            <span class="login" style="color: #909399">登录手机号</span>
          </div>
          <div v-for="item in login" :key="item.mobile" style="margin:10px 0 10px 0">
            <i class="el-icon-s-custom icon" :style="{color:(item.cur?'#67C23A':'lightgray')}"/>
            <span class="login">{{item.mobile}}</span>
          </div>
        </el-card>
        <el-input class="family text pwd" size="small" v-model.trim="pwd.oldPwd"
                  maxlength="20" auto-complete="new-password">
          <template slot="prepend">原密码</template>
        </el-input>
        <el-input class="family text pwd" size="small" v-model.trim="pwd.newPwd"
                  maxlength="20" auto-complete="new-password">
          <template slot="prepend">新密码</template>
        </el-input>
        <el-input class="family text pwd" size="small" v-model.trim="pwd.checkPwd"
                  maxlength="20" auto-complete="new-password">
          <template slot="prepend">确认密码</template>
        </el-input>
        <el-button type="success" @click="updPwd" size="small" :loading="loadingPwd"
                   icon="el-icon-edit">修改密码
        </el-button>
      </div>
      <div class="div" style="align-items: start">
        <el-table border stripe size="small" :data="ips" :row-style="{height:'60px'}"
                  :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal',
                  fontSize:'13px',height: '40px'}"
                  style="width: 300px" max-height="160">
          <el-table-column label="登录免验IP" width="236" :resizable="false">
            <template slot-scope="scope">
              <div style="display: flex;flex-direction:column">
                <span style="font-size: 13px;height: 20px;line-height: 20px">{{scope.row.ip}}</span>
                <span style="color: #C0C4CC;height: 20px;line-height: 20px">{{scope.row.location}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="63" :resizable="false" header-align="center">
            <template slot="header">
              <div>
                <el-button class="buttonTable" size="mini" type="success"
                           icon="el-icon-plus" @click="addIp"/>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="scope">
                <el-popover placement="right" v-model="scope.row.visibleDel">
                  <div style="display: flex;justify-content: center;align-items: center">
                    <span class="spanPopo">确定删除？</span>
                    <el-button class="buttonPopo" size="mini" type="success"
                               @click="delIp(scope.row.id)" icon="el-icon-check"/>
                  </div>
                  <el-button slot="reference" class="buttonTable" size="mini" type="danger"
                             icon="el-icon-delete"/>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    created () {
      this.getBuyer()
      this.getLogin()
      this.getIpList()
    },
    data () {
      return {
        user: {
          id: '',
          name: '',
          contact: '',
          tel: '',
          qq: '',
          address: '',
          vipdate: '',
          regdate: ''
        },
        login: [''],
        pwd: {
          oldPwd: '',
          newPwd: '',
          checkPwd: ''
        },
        loadingUpd: false,
        loadingPwd: false,
        ips: []
      }
    },
    methods: {
      dateFormat (date) {
        if (date === undefined) {
          return
        }
        date = new Date(date)
        const y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        return y + '-' + MM + '-' + d
      },
      async getBuyer () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getBuyer')
          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }

          this.user.id = res.data.id
          this.user.name = res.data.name
          this.user.contact = res.data.contact === undefined ? '' : res.data.contact
          this.user.tel = res.data.tel === undefined ? '' : res.data.tel
          this.user.qq = res.data.qq === undefined ? '' : res.data.qq
          this.user.address = res.data.address === undefined ? '' : res.data.address
          this.user.vipdate = this.dateFormat(res.data.vipdate)
          this.user.regdate = this.dateFormat(res.data.regdate)
        } catch (e) {
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async getLogin () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getLogin')
          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          this.login = res.data
        } catch (e) {
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async getIpList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getIpWhiteList')
          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.ips = []
            this.$msg.warning(res.msg)
            return
          }

          this.ips = res.data
          this.ips = this.ips.map(item => {
            this.$set(item, 'visibleDel', false)
            return item
          })
        } catch (e) {
          this.ips = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async updBuyer () {
        try {
          this.loadingUpd = true
          const { data: res } = await this.$http.post('updBuyer', {
            contact: this.user.contact,
            tel: this.user.tel,
            qq: this.user.qq,
            address: this.user.address
          })
          this.loadingUpd = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.$msg.success('修改成功！')
        } catch (e) {
          this.loadingUpd = false
          this.$msg.error(this.netError)
        }
      },
      async updPwd () {
        if (this.pwd.oldPwd === '') {
          return this.$msg.warning('请输入原密码！')
        }
        if (this.pwd.newPwd === '') {
          return this.$msg.warning('请输入新密码！')
        }
        if (this.pwd.checkPwd === '') {
          return this.$msg.warning('请输入确认密码！')
        }
        if (this.pwd.newPwd !== this.pwd.checkPwd) {
          this.pwd.newPwd = ''
          this.pwd.checkPwd = ''
          return this.$msg.warning('两次密码不一致！')
        }

        try {
          this.loadingPwd = true
          const { data: res } = await this.$http.get('updBuyerPwd',
            {
              params: {
                oldPwd: this.pwd.oldPwd,
                newPwd: this.pwd.newPwd
              }
            })
          this.loadingPwd = false
          this.pwd.oldPwd = ''
          this.pwd.newPwd = ''
          this.pwd.checkPwd = ''

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.$msg.success('改密成功！')
        } catch (e) {
          this.loadingPwd = false
          this.$msg.error(this.netError)
        }
      },
      async addIp () {
        try {
          const { data: res } = await this.$http.get('addIpWhite')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          await this.getIpList()
          this.$msg.success('新增成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async delIp (id) {
        try {
          const { data: res } = await this.$http.get('delIpWhite',
            {
              params: {
                id: id
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          await this.getIpList()
          this.$msg.success('删除成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #C0C4CC;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 303px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-input-group__prepend {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    padding: 0 15px;
    width: 52px;
  }

  .text {
    width: 303px;
    margin-bottom: 25px;
  }

  .login {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #606266;
  }

  /deep/ .el-card__header {
    padding: 10px 15px 10px 15px;
  }

  /deep/ .el-card__body {
    padding: 5px 15px 5px 15px;
  }

  .icon {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    margin-right: 3px;
  }

  .pwd /deep/ .el-input__inner {
    -webkit-text-security: disc !important;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }
</style>
